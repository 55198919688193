import { CalculatedResultItemProps, CartProductItemProps } from "@/components/Cart/constants";
import { MinProductItemProps } from "@/components/Product/constants";
import { RegisteredProductProps } from "@/components/Warranty/constants";
import { AttributeItem, CategoryItem, MamapediaCategoryItem } from "@/lib/constants";
import { v4 as uuidv4 } from 'uuid';

// user
export interface UserState {
  userInfo: string;
  updateUser: (user: User) => void;
  getUser: () => User;
}

export interface User {
  user_id: number | undefined;
  membershiplevel: string;
  token: string;
  refresh_token: string;
  barcode: string;
  email: string;
  mooimom_point: number;
  mooimom_point_deactivate_date: string | null;
  name: string;
  country_code: string;
  phone: string;
  referral_code: string;
  uuid: string;
  birthday: string | null;
  gender: string;
  order_status: {
    cancel: number;
    cancel_shipping: number;
    completed: number;
    processing: number;
    reviewed: number;
    draft: number;
    paid: number;
    delivered: number;
    return: number;
  };
  married: boolean;
  login_type: string;
  update_date?: number | null;
}

export const initUser: User = {
  user_id: undefined,
  membershiplevel: '',
  token: '',
  refresh_token: '',
  barcode: '',
  email: '',
  mooimom_point: 0,
  mooimom_point_deactivate_date: null,
  name: '',
  country_code: '',
  phone: '',
  referral_code: '',
  uuid: uuidv4(),
  birthday: null,
  gender: '',
  order_status: {
    cancel: 0,
    cancel_shipping: 0,
    completed: 0,
    processing: 0,
    reviewed: 0,
    draft: 0,
    paid: 0,
    delivered: 0,
    return: 0
  },
  married: false,
  login_type: 'guest',
  update_date: null
}

// wishlist
export interface WishlistState {
  wishlist: Wishlist;
  resetWishlist: (wishlist: Wishlist) => void;
  updateWishlist: (wishlist: Wishlist) => void;
}

export interface Wishlist {
  products: Array<MinProductItemProps>
}

export const initWishlist: Wishlist = {
  products: []
}

// cart
export interface CartsState {
  carts: string;
  updateCarts: (carts: Carts) => void;
  getCarts: () => Carts;
}

export interface Carts {
  update_date: number | null;
  data: Array<CartProductItemProps>;
}

export const initCarts: Carts = {
  update_date: null,
  data: []
}

// address
export interface AddressState {
  addressInfo: string;
  updateAddress: (address: Address) => void;
  getAddress: () => Address;
}

export interface AddressItem {
  id: number | undefined;
  name: string;
  customer: number;
  mobile: string;
  country: string;
  state: string;
  city: string;
  district: string;
  area: string;
  street: string;
  district_id: number;
  zip_code: number;
}

export interface Address {
  update_date: number | null;
  data: Array<AddressItem>;
}

export const initAddress: Address = {
  update_date: null,
  data: []
}

// calculate result
export interface ResultState {
  resultData: string;
  updateResult: (result: CalculatedResultItemProps) => void;
  getResult: () => CalculatedResultItemProps;
}

export const initResult: CalculatedResultItemProps = {
  voucher_list: [],
  use_voucher: true,
  voucher_discount: 0,
  voucher_gift: [],
  promo_list: [],
  gift_list: [],
  total_amount: 0,
  normal_amount: 0,
  gift_promo_list: [],
  discount_shipping: null,
  flash_sales: [],
  discount: 0,
  discount_name: "",
  status: "",
  cashback: 0,
  push_purchase_note: "",
  redeem_data: {},
  subtotal: 0,
  voucher_type: ""
}

// Top Banner
export interface TopBannerState {
  topBanner: string;
  updateTopBanner: (topBanner: TopBanner) => void;
  getTopBanner: () => TopBanner;
}

export interface TopBannerItem {
  name: string | null;
  link: string | null;
  desktop_image: string | null;
  mobile_image: string | null;
  image_alt: string | null;
}

export interface TopBanner {
  update_date: number | null;
  data: Array<TopBannerItem>;
}

export const initTopBanner: TopBanner = {
  update_date: null,
  data: []
}

// categories
export interface CategoryState {
  categories: string;
  updateCategories: (categories: Categories) => void;
  getCategories: () => Categories;
}

export interface Categories {
  update_date: number | null;
  data: Array<CategoryItem>;
}

export const initCategories: Categories = {
  update_date: null,
  data: []
}

// attributes
export interface AttributeState {
  attributes: string;
  updateAttributes: (attributes: Attributes) => void;
  getAttributes: () => Attributes;
}

export interface Attributes {
  update_date: number | null;
  data: Array<AttributeItem>;
}

export const initAttributes: Attributes = {
  update_date: null,
  data: []
}

// Recommendation
export interface RecommendState {
  recommend: string;
  updateRecommend: (recommend: Recommend) => void;
  getRecommend: () => Recommend;
}

export interface Recommend {
  update_date: number | null;
  data: Array<MinProductItemProps>;
}

export const initRecommend: Recommend = {
  update_date: null,
  data: []
}

// Focus Product
export interface FocusProductState {
  focusProduct: string;
  updateFocusProduct: (focusProduct: FocusProduct) => void;
  getFocusProduct: () => FocusProduct;
}

export interface FocusProduct {
  update_date: number | null;
  data: Array<MinProductItemProps>;
}

export const initFocusProduct: FocusProduct = {
  update_date: null,
  data: []
}

// mamapedia categories
export interface MamapediaCategoryState {
  mamapediaCategories: string;
  updateMamapediaCategories: (mamapediaCategories: MamapediaCategories) => void;
  getMamapediaCategories: () => MamapediaCategories;
}

export interface MamapediaCategories {
  update_date: number | null;
  data: Array<MamapediaCategoryItem>;
}

export const initMamapediaCategories: MamapediaCategories = {
  update_date: null,
  data: []
}

// warranty
export interface WarrantyState {
  warranty: string;
  updateWarranty: (warranty: Warranty) => void;
  getWarranty: () => Warranty;
}

export interface Warranty {
  update_date: number | null;
  data: Array<RegisteredProductProps>;
}

export const initWarranty: Warranty = {
  update_date: null,
  data: []
}

// suggest search
export interface SuggestSearchState {
  suggestSearch: string;
  updateSuggestSearch: (suggestSearch: SuggestSearch) => void;
  getSuggestSearch: () => SuggestSearch;
}

export interface SuggestSearch {
  update_date: number | null;
  data: SuggestSearchProps;
}

export interface SuggestSearchProps {
  keywords: Array<String>;
  products: Array<MinProductItemProps>;
}

export const initSuggestSearch: SuggestSearch = {
  update_date: null,
  data: {
    keywords: [],
    products: []
  }
}

// popup
export interface PopupState {
  popup: string;
  updatePopup: (popup: PopupInfo) => void;
  getPopup: () => PopupInfo;
}

export interface PopupInfo {
  update_date: number | null;
  data: {
    info: Array<PopupItem>;
    record: Array<PopupRecord>;
  }
}

export interface PopupItem {
  id: number;
  name: string;
  slug: string;
  order: number;
  start_date: string | null;
  end_date: string | null;
  desktop_image: string;
  mobile_image: string;
  match_url: string | null;
  max_times: number;
  type: string;
  wait_seconds: number | null;
  scroll_depth: number | null;
  link: string | null;
}

export interface PopupRecord {
  popup_id: number;
  popup_count: number;
}

export const initPopup: PopupInfo = {
  update_date: null,
  data: {
    info: [],
    record: []
  }
}
